export const breakingPoints = {
  sm: 767,
  md: 991,
  lg: 992,
};

export const themecolors ={
  background: "#F9F7F2",
  main: "#A8C5BC",
  black: "#161A21",
  white: "#fff",
}