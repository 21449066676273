export const colorsOptions = [
  { text: "Amethyst", color: "#9966CC" },
  { text: "Aubergine", color: "#3B0910" },
  { text: "Aqua", color: "#00FFFF" },
  { text: "Beige", color: "#F5F5DC" },
  { text: "Black", color: "#000000" },
  { text: "Blush", color: "#DE5D83" },
  { text: "Bronze", color: "#CD7F32" },
  { text: "Brown", color: "#A52A2A" },
  { text: "Burgundy", color: "#800020" },
  { text: "Burnt Sienna", color: "#E97451" },
  { text: "Camel", color: "#C19A6B" },
  { text: "Caramel", color: "#FFD700" },
  { text: "Cerulean", color: "#007BA7" },
  { text: "Champagne", color: "#F7E7CE" },
  { text: "Charcoal", color: "#36454F" },
  { text: "Cherry", color: "#DE3163" },
  { text: "Chocolate", color: "#D2691E" },
  { text: "Cobalt Blue", color: "#0047AB" },
  { text: "Coffee", color: "#6F4E37" },
  { text: "Coral", color: "#FF7F50" },
  { text: "Coral Pink", color: "#F88379" },
  { text: "Coral Red", color: "#FF4040" },
  { text: "Cornsilk", color: "#FFF8DC" },
  { text: "Crimson", color: "#DC143C" },
  { text: "Cyan", color: "#00FFFF" },
  { text: "Cyan Blue", color: "#003366" },
  { text: "Denim", color: "#1560BD" },
  { text: "Denim Blue", color: "#2243B6" },
  { text: "Ebony", color: "#555D50" },
  { text: "Electric Blue", color: "#7DF9FF" },
  { text: "Forest", color: "#228B22" },
  { text: "Forest Green", color: "#228B22" },
  { text: "Gold", color: "#FFD700" },
  { text: "Goldenrod", color: "#DAA520" },
  { text: "Grape", color: "#6F2DA8" },
  { text: "Gray", color: "#808080" },
  { text: "Green", color: "#008000" },
  { text: "Honeydew", color: "#F0FFF0" },
  { text: "Hot Pink", color: "#FF69B4" },
  { text: "Icy Blue", color: "#71A6D2" },
  { text: "Indigo", color: "#4B0082" },
  { text: "Ivory", color: "#FFFFF0" },
  { text: "Jade", color: "#00A86B" },
  { text: "Lavender", color: "#E6E6FA" },
  { text: "Lavender Gray", color: "#BDBBD7" },
  { text: "Lavender Pink", color: "#FBAED2" },
  { text: "Lemon", color: "#FFF700" },
  { text: "Lilac", color: "#C8A2C8" },
  { text: "Lime", color: "#00FF00" },
  { text: "Magenta", color: "#FF00FF" },
  { text: "Mahogany", color: "#C04000" },
  { text: "Maroon", color: "#800000" },
  { text: "Mauve", color: "#E0B0FF" },
  { text: "Midnight Blue", color: "#191970" },
  { text: "Midnight Green", color: "#004953" },
  { text: "Mint", color: "#00FF00" },
  { text: "Mint Green", color: "#98FF98" },
  { text: "Moss Green", color: "#8A9A5B" },
  { text: "Mustard", color: "#FFDB58" },
  { text: "Navy", color: "#000080" },
  { text: "Olive", color: "#808000" },
  { text: "Olive Brown", color: "#645403" },
  { text: "Olive Drab", color: "#6B8E23" },
  { text: "Orange", color: "#FFA500" },
  { text: "Orchid", color: "#DA70D6" },
  { text: "Orchid Pink", color: "#F2BDCD" },
  { text: "Peach", color: "#FFE5B4" },
  { text: "Periwinkle", color: "#CCCCFF" },
  { text: "Pewter", color: "#B9AFA3" },
  { text: "Pine Green", color: "#01796F" },
  { text: "Pink", color: "#FFC0CB" },
  { text: "Plum", color: "#DDA0DD" },
  { text: "Powder Blue", color: "#B0E0E6" },
  { text: "Powder Pink", color: "#FFB2D0" },
  { text: "Purple", color: "#800080" },
  { text: "Raspberry", color: "#E30B5C" },
  { text: "Red", color: "#FF0000" },
  { text: "Rose Gold", color: "#B76E79" },
  { text: "Royal Blue", color: "#4169E1" },
  { text: "Ruby", color: "#E0115F" },
  { text: "Ruby Red", color: "#9B111E" },
  { text: "Ruby Rose", color: "#9B111E" },
  { text: "Salmon", color: "#FA8072" },
  { text: "Salmon Pink", color: "#FF91A4" },
  { text: "Saffron", color: "#F4C430" },
  { text: "Sand", color: "#C2B280" },
  { text: "Seafoam", color: "#64E986" },
  { text: "Sienna", color: "#A0522D" },
  { text: "Slate", color: "#708090" },
  { text: "Smoke", color: "#738276" },
  { text: "Steel Blue", color: "#4682B4" },
  { text: "Steel Gray", color: "#262335" },
  { text: "Tan", color: "#D2B48C" },
  { text: "Tangerine", color: "#F28500" },
  { text: "Taupe", color: "#483C32" },
  { text: "Teal", color: "#008080" },
  { text: "Terracotta", color: "#E2725B" },
  { text: "Tomato", color: "#FF6347" },
  { text: "Turquoise", color: "#40E0D0" },
  { text: "Vanilla", color: "#F3E5AB" },
  { text: "White", color: "#FFFFFF" },
  { text: "Yellow", color: "#FFFF00" },
];
