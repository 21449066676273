import React, { useEffect, useState } from "react";
import {
  CategoriesContainer,
  Category,
  Circle,
  Container,
  Gender,
  Link,
  PoweredBy,
  Root,
  SocialMediaContainer,
  Text,
  Title,
  Wrapper,
  Logo,
} from "./styles";
import { FaFacebookF, FaInstagram, FaRegCopyright } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";
import { SiGmail } from "react-icons/si";
import { useGetCategoriesQuery } from "../../../apis/categories/getCategories";
import circlelogo from "../../../static/circlelogo.png";
export default function MobileFooter() {
  const { isLoading, response } = useGetCategoriesQuery();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      setCategories(response?.data);
    }
  }, [isLoading]);

  const handleGmail = () => {
    const recipientEmail = "info@pointnul.com";
    const mailtoUrl = `mailto:
    ${recipientEmail}
    `;
    // Open the default email client (Gmail) with the pre-filled recipient email
    window.open(mailtoUrl);
  };
  return (
    <Root>
      <Container>
        {/* <Wrapper style={{ width: "40%" }}>
          <Title>All Categories</Title>
          <CategoriesContainer>
            {categories?.slice(0, 2)?.map(({ category }) => (
              <Category>{category}</Category>
            ))}
            {categories?.length > 2 && <Category key="more">. . .</Category>}
          </CategoriesContainer>
        </Wrapper> */}
        <Wrapper style={{ width: "80%", alignSelf: "flex-start" }}>
          <Title>Policy</Title>
          <CategoriesContainer>
            <Gender>No Refund</Gender>
            <Gender>Exchange within 7 days</Gender>
          </CategoriesContainer>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper style={{ width: "40%" }}>
          <Title>Social Media</Title>
          <SocialMediaContainer>
            <Circle
              onClick={() => {
                window.open(
                  "https://www.tiktok.com/@point.nul?_t=8lvf4vo0tyk&_r=1",
                  "_blank"
                );
              }}
            >
              <FaTiktok style={{ fontSize: "18px" }} />
            </Circle>
            <Circle
              onClick={() => {
                window.open(
                  "https://www.instagram.com/pointnul?igsh=MTMzdDl2a2p2MTM2bg==",
                  "_blank"
                );
              }}
            >
              <FaInstagram style={{ fontSize: "18px" }} />
            </Circle>
            <Circle onClick={handleGmail}>
              <SiGmail style={{ fontSize: "18px" }} />
            </Circle>
          </SocialMediaContainer>
        </Wrapper>
        <Wrapper style={{ fontSize: "80px", alignSelf: "flex-end" }}>
          <Logo src={circlelogo} />
        </Wrapper>
      </Container>
      <PoweredBy>
        Copyright
        <FaRegCopyright
          style={{
            fontSize: "15px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        />
        2024 <Link href="https://cedars-js.com">cedars-js.com</Link>
      </PoweredBy>
    </Root>
  );
}
