import styled from "styled-components";
import { breakingPoints, themecolors } from "../../../global/theme";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
`;

export const Name = styled.span`
  font-size: 30px;
  /* font-size: 25px; */
  font-weight: bold;
  text-transform: capitalize;
  color:${themecolors.black}
`;


export const PriceContainer = styled.div`
display: flex;
flex-direction: row;
gap:10px


`;
export const Price = styled.span`
font-size: 25px;
  font-weight: normal;
  color :${themecolors.black};
  text-decoration-line:${props=>props.sale?"line-through":"null"};

`;

export const DiscountPrice = styled.span`
font-size: 25px;
  font-weight: normal;
  color :${themecolors.black}

`;

export const Sale = styled.span`
font-size: 12px;
  font-weight: normal;
  background-color: red;
  color :${themecolors.white};
  border-radius:10px;
  width: 70px;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;

`;



export const Description = styled.span`
  font-size: 18px;
  /* font-size: 16px; */
  white-space: pre-wrap;
  font-weight: light;
  @media (max-width: ${breakingPoints.sm}px) {
    font-size: 15px;
  }
`;
export const QuantityPurchase = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
`;

export const CounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;
export const Quantity = styled.div`
  background-color: ${themecolors.black};
  color: ${themecolors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 30px;
  border-radius: 20px;
  font-weight: bold;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
`;
export const AddtoCart = styled.button`
  width: 150px;
  border-radius: 20px;
  background-color: ${({ isDisabled }) => (isDisabled ? "lightgray" : themecolors.black)};
  color: ${themecolors.white};
  height: 40px;
  outline: none;
  border: none;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
`;

export const addToCartStyle = (isDisabled) => ({
  textTransform: "capitalize",
  color: themecolors.white,
  borderRadius: "10px",
  width: "140px",
  height: "40px",
  backgroundColor: isDisabled ? "lightgray" : themecolors.black,
  cursor: isDisabled ? "not-allowed" : "pointer",
});
